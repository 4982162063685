// extracted by mini-css-extract-plugin
export var buttons = "styles-module--buttons--85f99";
export var container = "styles-module--container--de47b";
export var deleteButton = "styles-module--deleteButton--da3ad";
export var deleteButtons = "styles-module--deleteButtons--e0a72";
export var deleteIcon = "styles-module--deleteIcon--672fe";
export var deleteMenu = "styles-module--deleteMenu--7e277";
export var deleteTitle = "styles-module--deleteTitle--31f99";
export var desktop = "1340px";
export var fileUpload = "styles-module--fileUpload--1cf82";
export var fontUploadSection = "styles-module--fontUploadSection--80417";
export var formSection = "styles-module--formSection--d3c3e";
export var giant = "2200px";
export var label = "styles-module--label--3f003";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var spinner = "styles-module--spinner--b71d3";
export var tablet = "769px";
export var title = "styles-module--title--305b5";
export var visible = "styles-module--visible--ced5c";
import React from 'react';
import { SliceConfig } from '@cmc/opto-toolkit/components';
import { IResourceLinks } from '@cmc/opto-sanity';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import ResourceLink from './components/ResourceLink';

interface IProps {
  data: IResourceLinks;
}

const ResourceLinks = ({ data: { title, sliceConfig, links } }: IProps) => {
  return (
    <SliceConfig config={sliceConfig}>
      <div className={styles.container}>
        <h2 className={cn(`t-h2`, styles.title)}>{title}</h2>
        <div className={styles.links}>
          {links.map((link, i) => (
            <ResourceLink className={styles.link} {...link} key={i} />
          ))}
        </div>
      </div>
    </SliceConfig>
  );
};

export default ResourceLinks;

// extracted by mini-css-extract-plugin
export var container = "styles-module--container--78239";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--c6c45";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var properties = "styles-module--properties--ab323";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var text = "styles-module--text--23b7d";
import React from 'react';
import { SliceConfig } from '@cmc/opto-toolkit/components';
import { IHomepageAtf } from '@cmc/opto-sanity';
import { SVG, IntersectionAnimation } from '@cmc/opto-common/components';
import cn from 'classnames';
import * as styles from './styles.module.scss';

interface IProps {
  data: IHomepageAtf;
}

const Hero = ({ data: { sliceConfig } }: IProps) => {
  return (
    <div className={styles.container}>
      <SliceConfig
        config={{
          ...sliceConfig,
          slicePadding: {
            paddingBottom: 'small',
            paddingTop: 'small'
          },
          backgroundColor: {
            hex: 'var(--theme-bg-color)'
          },
          textColor: {
            hex: 'var(--theme-text-color)'
          }
        }}
      >
        <div className={styles.content}>
          <IntersectionAnimation delay={100}>
            <h1
              className={cn('t-d2', styles.title)}
            >{`Invest in\nInnovation`}</h1>
          </IntersectionAnimation>

          <IntersectionAnimation delay={200}>
            <SVG svg="logo" />
          </IntersectionAnimation>
        </div>
      </SliceConfig>
    </div>
  );
};

export default Hero;

// extracted by mini-css-extract-plugin
export var bottomBorder = "styles-module--bottomBorder--33e52";
export var container = "styles-module--container--c1955";
export var content = "styles-module--content--9558e";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var paddingBottomRegular = "styles-module--paddingBottomRegular--f0a53";
export var paddingBottomSmall = "styles-module--paddingBottomSmall--a3014";
export var paddingBottomTiny = "styles-module--paddingBottomTiny--3ddbf";
export var paddingSidesRegular = "styles-module--paddingSidesRegular--beb52";
export var paddingSidesSmall = "styles-module--paddingSidesSmall--1f5f8";
export var paddingSidesTiny = "styles-module--paddingSidesTiny--c38b5";
export var paddingTopRegular = "styles-module--paddingTopRegular--4e2ca";
export var paddingTopSmall = "styles-module--paddingTopSmall--5264d";
export var paddingTopTiny = "styles-module--paddingTopTiny--e3920";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
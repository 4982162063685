import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { globalHistory } from '@reach/router';
import * as styles from '@cmc/opto-common/styles/disableScroll.module.scss';
import { INotification } from '@cmc/opto-toolkit/components';

interface IPagePasswordStatus {
  [key: string]: `locked` | `unlocked`;
}

interface IPageColorTheme {
  backgroundColor: string;
  textColor: string;
}

export interface IAppContext {
  isHeaderOpen: boolean;
  setIsHeaderOpen: React.Dispatch<React.SetStateAction<boolean>>;
  notification: INotification;
  updateNotification: (newMessage: string) => void;
  pagePasswordStatus: IPagePasswordStatus;
  setPagePasswordStatus: React.Dispatch<
    React.SetStateAction<IPagePasswordStatus>
  >;
  pageColorTheme: IPageColorTheme;
}

export const AppContext = createContext<IAppContext>({} as IAppContext);

interface IProps {
  children: React.ReactNode;
}

const pageColorThemes: IPageColorTheme[] = [
  {
    backgroundColor: 'var(--color-yellow)',
    textColor: 'var(--color-almost-black)'
  },
  {
    backgroundColor: 'var(--color-red)',
    textColor: 'var(--color-almost-black)'
  },
  {
    backgroundColor: 'var(--color-pink)',
    textColor: 'var(--color-pink-dark)'
  },
  {
    backgroundColor: 'var(--color-blue)',
    textColor: 'var(--color-blue-dark)'
  },
  {
    backgroundColor: 'var(--color-green)',
    textColor: 'var(--color-green-dark)'
  }
];

const AppProvider = ({ children }: IProps) => {
  const getInitialColorTheme = () => {
    return Math.floor(Math.random() * pageColorThemes.length);
  };

  const [isHeaderOpen, setIsHeaderOpen] = useState(false);
  const [notification, setNotification] = useState<INotification>({
    message: ``,
    keyToggle: 0
  });
  const [pagePasswordStatus, setPagePasswordStatus] =
    useState<IPagePasswordStatus>({});
  const [activeColorThemeIndex, setActiveColorThemeIndex] = useState(
    getInitialColorTheme()
  );

  // Effects on page change
  useEffect(() => {
    return globalHistory.listen(() => {
      // Close header
      setIsHeaderOpen(false);

      // Update page color theme
      const incrementActiveColorThemeIndex = () => {
        setActiveColorThemeIndex((prev) => {
          if (prev === pageColorThemes.length - 1) {
            return 0;
          }

          return prev + 1;
        });
      };

      incrementActiveColorThemeIndex();
    });
  }, []);

  const pageColorTheme = pageColorThemes[activeColorThemeIndex];

  // Disable page scroll
  useEffect(() => {
    if (isHeaderOpen) {
      document.body.classList.add(styles.disableScroll);
    } else {
      document.body.classList.remove(styles.disableScroll);
    }
  }, [isHeaderOpen]);

  // Notification
  const updateNotification = (newMessage: string) => {
    setNotification((prev) => ({
      message: newMessage,
      keyToggle: prev.keyToggle === 0 ? 1 : 0
    }));
  };

  // ---------------------------------------------------------------------------
  // render

  const contextProps = {
    isHeaderOpen,
    setIsHeaderOpen,
    pagePasswordStatus,
    setPagePasswordStatus,
    notification,
    updateNotification,
    pageColorTheme
  };

  return (
    <AppContext.Provider value={contextProps}>{children}</AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppProvider;

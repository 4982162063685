import React, { useEffect, useState } from 'react';
import { SVG } from '@cmc/opto-common/components';
import { SliceConfig } from '@cmc/opto-toolkit/components';
import cn from 'classnames';
import * as styles from './styles.module.scss';

const Footer = () => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) return null;

  return (
    <div>
      <SliceConfig
        config={{
          backgroundColor: { hex: 'var(--theme-bg-color)' },
          textColor: { hex: 'var(--theme-text-color)' }
        }}
      >
        <SVG svg="logo" className={styles.wordmark} />

        <div className={cn(styles.bottomText)}>
          <p className="t-caption">© Opto {new Date().getFullYear()}</p>
          <p className="t-button">
            Made with{' '}
            <a
              className={styles.lamLink}
              href="https://www.loveandmoney.agency/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Love + Money
            </a>
          </p>
        </div>
      </SliceConfig>
    </div>
  );
};

export default Footer;

import { IFont } from '@cmc/opto-sanity';

const getFontFamilyCss = (fonts: IFont[] = []) => {
  let cssString = ``;

  fonts.forEach((font) => {
    cssString += `
			@font-face {
				font-family: "${font.title}";
				font-style: "normal";
        ${font?.fontWeight && `font-weight: ${font.fontWeight};`}
				font-display: "block";
				src: url("${font.woff2.asset.url}") format("woff2")${
      font?.woff?.asset?.url
        ? `,url("${font.woff.asset.url}") format("woff")`
        : ``
    };
			}
		`;
  });

  return cssString;
};

export default getFontFamilyCss;

// extracted by mini-css-extract-plugin
export var button = "styles-module--button--b5e34";
export var content = "styles-module--content--9098b";
export var desktop = "1340px";
export var giant = "2200px";
export var icon = "styles-module--icon--bda3a";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var text = "styles-module--text--57944";
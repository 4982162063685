import React, { useState } from 'react';
import { SliceConfig } from '@cmc/opto-toolkit/components';
import { PGSlices, TPGSlice } from '@cmc/opto-toolkit/playground';
import { camelCaseToTitleCase } from '@cmc/opto-common/utils';
import { IPagebuilderSlices } from '@cmc/opto-sanity';
import { Button } from '@cmc/opto-common/components';
import cn from 'classnames';
import * as styles from './styles.module.scss';

interface IProps {
  data: IPagebuilderSlices;
}

const PagebuilderSlices = ({ data: { sliceConfig } }: IProps) => {
  const playgroundSlices = Object.keys(PGSlices) as TPGSlice[];

  const [activeSlice, setActiveSlice] = useState<TPGSlice>(playgroundSlices[0]);

  const PlaygroundElement = PGSlices[activeSlice];

  return (
    <SliceConfig config={sliceConfig}>
      <h2 className={cn(`t-h2`, styles.selectText)}>Select Slice</h2>
      <div className={styles.selectSliceButtons}>
        {playgroundSlices.map((slice, i) => (
          <Button
            key={i}
            variant={activeSlice === slice ? `primary` : `secondary`}
            onClick={() => setActiveSlice(slice)}
            text={camelCaseToTitleCase(slice)}
          />
        ))}
      </div>

      <PlaygroundElement />
    </SliceConfig>
  );
};

export default PagebuilderSlices;

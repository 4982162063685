import React, { useRef } from 'react';
import cn from 'classnames';
import { SVG } from '@cmc/opto-common/components';
import { Link } from 'gatsby';
import * as styles from './styles.module.scss';

interface IProps {
  className?: string;
  to: string;
  text: string;
  direction: 'prev' | 'next';
}

const PageNavButton = ({ className, to, text, direction }: IProps) => {
  const buttonRef = useRef(
    null
  ) as React.MutableRefObject<HTMLAnchorElement | null>;

  return (
    <Link
      // Don't know how to resolve ref type mismatch
      // https://github.com/gatsbyjs/gatsby/issues/34325
      /* @ts-ignore */
      ref={buttonRef}
      className={cn(className, styles.button)}
      to={to}
    >
      <div className={styles.content}>
        {direction === `prev` && (
          <SVG svg="arrowLeft" className={styles.icon} />
        )}
        <span className={cn(`t-button`, styles.text)}>{text}</span>
        {direction === `next` && (
          <SVG svg="arrowRight" className={styles.icon} />
        )}
      </div>
    </Link>
  );
};

export default PageNavButton;

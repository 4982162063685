import React from 'react';
import { Hero } from '@cmc/opto-toolkit/slices';
import { usePlayground } from '@cmc/opto-toolkit/hooks';
import { IComponentSchemaField } from 'src/hooks/usePlayground';

const PgHero = () => {
  const schemaFields: IComponentSchemaField[] = [
    {
      title: `title`,
      type: `textInput`
    },
    {
      title: `sliceConfig`,
      type: `sliceConfig`
    }
  ];

  const { config, renderPlayground } = usePlayground(schemaFields);

  const previewComponent = <Hero data={config} />;

  return <>{renderPlayground(previewComponent, `slices`)}</>;
};

export default PgHero;

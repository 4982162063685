import React, { useCallback, useEffect, useState } from 'react';
import { useInterval } from '@cmc/opto-common/hooks';

interface IProps {
  text: string;
  animateOnMount?: boolean;
  trigger?: boolean;
}

const TextScrambler = ({ text, animateOnMount, trigger }: IProps) => {
  const UPDATE_CHARACTER_SPEED = 40;
  const FIND_CORRECT_CHARACTER_SPEED = 50;
  const CHARACTERS = 'abcdefghijklmnopqrstuvwxyz'.split('');

  const getRandomCharacter = useCallback(
    (actualCharacter: string) => {
      if (actualCharacter === ' ') return ' ';

      const randomCharacterIndex = Math.floor(
        Math.random() * CHARACTERS.length
      );

      return CHARACTERS[randomCharacterIndex];
    },
    [CHARACTERS]
  );

  const getScrambledText = useCallback(() => {
    const initialTextArray: string[] = [];

    if (!text?.length) {
      return ``;
    }

    for (let i = 0; i < text.length; i++) {
      initialTextArray.push(getRandomCharacter(text[i]));
    }

    return initialTextArray.join('');
  }, [getRandomCharacter, text]);

  const initialUnscrambledLetterIndex = animateOnMount ? -1 : text?.length;
  const initialDisplayText = animateOnMount ? getScrambledText() : text;

  const [unscrambledLetterIndex, setUnscrambledLetterIndex] = useState(
    initialUnscrambledLetterIndex
  );
  const [displayText, setDisplayText] = useState(initialDisplayText);
  const [isAnimatingHover, setIsAnimatingHover] = useState(false);
  const [isTriggered, setIsTriggered] = useState(false);

  const updateTextCharacters = () => {
    const newText = [];

    if (!text?.length) {
      return ``;
    }

    for (let i = 0; i < text.length; i++) {
      if (i <= unscrambledLetterIndex) {
        newText.push(text[i]);
      } else {
        newText.push(getRandomCharacter(text[i]));
      }
    }
    setDisplayText(newText.join(''));
  };

  const updateUnscrambledLetterIndex = () => {
    setUnscrambledLetterIndex((prev) => {
      if (text[prev + 1] === ' ') {
        return prev + 2;
      }
      return prev + 1;
    });
  };

  const isAnimationRunning = unscrambledLetterIndex < text?.length;

  const handleMouseEnter = () => {
    if (isAnimationRunning || trigger) return;
    setIsAnimatingHover(true);
  };

  const replayAnimation = useCallback(() => {
    setUnscrambledLetterIndex(-1);
    setDisplayText(getScrambledText());
    setIsAnimatingHover(false);
  }, [getScrambledText]);

  useInterval(
    updateTextCharacters,
    isAnimationRunning ? UPDATE_CHARACTER_SPEED : null
  );

  useInterval(
    updateUnscrambledLetterIndex,
    isAnimationRunning ? FIND_CORRECT_CHARACTER_SPEED : null
  );

  useEffect(() => {
    if (isAnimationRunning) return;

    if (isAnimatingHover) {
      replayAnimation();
    }
  }, [isAnimatingHover, isAnimationRunning, replayAnimation]);

  useEffect(() => {
    if (trigger) {
      setIsTriggered(true);
    }
  }, [trigger]);

  useEffect(() => {
    if (isTriggered) {
      setIsTriggered(false);
      replayAnimation();
    }
  }, [isTriggered, replayAnimation]);

  return <span onMouseEnter={handleMouseEnter}>{displayText}</span>;
};

export default TextScrambler;

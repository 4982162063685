import React from 'react';
import { IDesignPrinciples } from '@cmc/opto-sanity';
import { IntersectionAnimation } from '@cmc/opto-common/components';
import DesignPrinciple from './components/DesignPrinciple';
import * as styles from './styles.module.scss';

interface IProps {
  data: IDesignPrinciples;
}

const DesignPrinciples = ({ data: { principles } }: IProps) => {
  return (
    <>
      {principles.map((principle, i) => (
        <div key={i} className={styles.principle}>
          <IntersectionAnimation animation="fadeGrow" delay={i * 100}>
            <DesignPrinciple {...principle} />
          </IntersectionAnimation>
        </div>
      ))}
    </>
  );
};

export default DesignPrinciples;

// extracted by mini-css-extract-plugin
export var container = "styles-module--container--6b55c";
export var content = "styles-module--content--b4122";
export var desktop = "1340px";
export var giant = "2200px";
export var heading = "styles-module--heading--b4371";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var link = "styles-module--link--7d76f";
export var list = "styles-module--list--6fece";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
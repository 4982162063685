import React from 'react';
import cn from 'classnames';
import { hexToRGB, rgbToCMYK } from '@cmc/opto-common/utils';
import { CopyText } from '@cmc/opto-toolkit/components';
import * as styles from './styles.module.scss';

interface IProps {
  title: string;
  hex: string;
  textColor?: string;
  pantone?: string;
  pairTile?: boolean;
}

const ColorGridTile = ({
  hex,
  title,
  pantone,
  textColor,
  pairTile
}: IProps) => {
  const rgb = hexToRGB(hex);
  const { red, green, blue } = rgb;

  const { c, m, y, k } = rgbToCMYK(rgb);

  const getTextColor = () => {
    if (textColor) {
      return textColor;
    }

    const luminance =
      (0.299 * parseFloat(red) +
        0.587 * parseFloat(green) +
        0.114 * parseFloat(blue)) /
      255;

    if (luminance > 0.5) {
      return 'var(--color-black)';
    }
    return 'var(--color-white)';
  };

  const addBorder = hex.toLowerCase() === '#ffffff';

  return (
    <div
      className={cn(styles.container, { [styles.border]: addBorder })}
      style={{ color: getTextColor(), background: hex }}
    >
      <h3 className={cn('t-h2')}>{title}</h3>
      <div className={cn('t-b2', styles.bottomText)}>
        <div className={styles.bottomLeftEdge}>
          <CopyText text={hex} />
          <CopyText text={`R ${red} G ${green} B ${blue}`} />
        </div>

        {pairTile && (
          <div className={styles.bottomRightEdge}>
            <CopyText text={`C ${c} M ${m} Y ${y} K ${k}`} />

            {pantone && <CopyText text={pantone} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default ColorGridTile;

import React from 'react';
import * as Slices from '@cmc/opto-toolkit/slices';
import { ISliceConfig } from '@cmc/opto-sanity';

interface IProps {
  data: {
    _type: string;
    sliceConfig?: ISliceConfig;
  };
}

const SanitySlice = (props: IProps) => {
  const { data } = props;
  const { _type: sliceType } = data;

  if (!sliceType) {
    console.error(
      `SanitySlice requires a valid _type, but none was received. Check the /templates/page file to ensure a valid slice type is being passed to the SanitySlice component.`
    );

    return null;
  }

  const sliceName =
    sliceType[0].toUpperCase() + sliceType.slice(1, sliceType.length);

  const SliceSection = (Slices as any)?.[sliceName];

  if (!SliceSection) {
    console.error(
      `Can't find Slice '${sliceName}', are you sure it has been configured in the slices directory?`
    );

    return null;
  }

  // const bottomBorder = !data.sliceConfig?.disableBottomBorder;

  return (
    <section
    // className={cn({
    //   [styles.bottomBorder]: bottomBorder
    // })}
    >
      <SliceSection {...props} />
    </section>
  );
};

export default SanitySlice;

import React from 'react';
import PlaygroundSection from '../PlaygroundSection';

interface IProps {
  previewComponent: JSX.Element;
}

const Canvas = ({ previewComponent }: IProps) => {
  return (
    <PlaygroundSection title="Preview">{previewComponent}</PlaygroundSection>
  );
};

export default Canvas;

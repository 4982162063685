// extracted by mini-css-extract-plugin
export var container = "styles-module--container--07966";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--5bc62";
export var gridItem = "styles-module--gridItem--869f9";
export var gridItemContent = "styles-module--gridItemContent--846f0";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var toggleButtons = "styles-module--toggleButtons--1dcab";
export var uppercase = "styles-module--uppercase--ec9d2";
import React from 'react';
import { SliceConfig } from '@cmc/opto-toolkit/components';
import {
  IntersectionAnimation,
  LayoutProvider,
  PortableTextRenderer,
  UniversalLink
} from '@cmc/opto-common/components';
import { ITextArticle } from '@cmc/opto-sanity';
import { useInView } from 'react-intersection-observer';
import cn from 'classnames';
import * as styles from './styles.module.scss';

interface IProps {
  data: ITextArticle;
}

const TextArticle = ({
  data: { _rawLeftColumn, _rawRightColumn, links, tags, sliceConfig }
}: IProps) => {
  const { ref, inView } = useInView({
    rootMargin: `-90px`,
    triggerOnce: true
  });

  return (
    <div ref={ref}>
      <SliceConfig config={sliceConfig}>
        <LayoutProvider grid>
          {_rawLeftColumn && (
            <div className={styles.leftColumn}>
              <IntersectionAnimation trigger={inView}>
                <PortableTextRenderer rawText={_rawLeftColumn} />
              </IntersectionAnimation>

              {tags?.[0] && (
                <IntersectionAnimation trigger={inView}>
                  <div className={styles.tags}>
                    {tags.map((tag, i) => (
                      <IntersectionAnimation
                        delay={150 * i}
                        key={i}
                        animation="fadeGrow"
                      >
                        <div className={cn(`t-button`, styles.tag)}>{tag}</div>
                      </IntersectionAnimation>
                    ))}
                  </div>
                </IntersectionAnimation>
              )}
            </div>
          )}
          {_rawRightColumn && (
            <div className={styles.rightColumn}>
              <IntersectionAnimation delay={150} trigger={inView}>
                <PortableTextRenderer rawText={_rawRightColumn} />
              </IntersectionAnimation>

              {links?.[0] && (
                <IntersectionAnimation delay={300} className={styles.buttons}>
                  {links?.map((link, i) => (
                    <UniversalLink variant="secondary" link={link} key={i} />
                  ))}
                </IntersectionAnimation>
              )}
            </div>
          )}
        </LayoutProvider>
      </SliceConfig>
    </div>
  );
};

export default TextArticle;

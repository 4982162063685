import React from 'react';
import { SliceConfig } from '@cmc/opto-toolkit/components';
import { IntersectionAnimation } from '@cmc/opto-common/components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { IMedia } from '@cmc/opto-sanity';
import cn from 'classnames';
import * as styles from './styles.module.scss';

interface IProps {
  data: IMedia;
}

const Media = ({ data: { mediaItems, sliceConfig } }: IProps) => {
  const numberOfColumns: () => number = () => {
    const numberOfItems = mediaItems.length;

    if (numberOfItems === 1) {
      return 1;
    }

    // If items is divisible by 2, but NOT by 3
    if (numberOfItems % 2 === 0 && numberOfItems % 3 !== 0) {
      return 2;
    }

    return 3;
  };

  return (
    <SliceConfig config={sliceConfig}>
      <IntersectionAnimation>
        <div
          className={cn(styles.grid, {
            [styles.oneColumnLayout]: numberOfColumns() === 1,
            [styles.twoColumnLayout]: numberOfColumns() === 2,
            [styles.threeColumnLayout]: numberOfColumns() === 3
          })}
        >
          {mediaItems.map((item, i) => (
            <IntersectionAnimation
              animation="fadeGrow"
              delay={i * 100}
              key={i}
              className={styles.mediaItemContainer}
            >
              {item._type === 'altImage' && (
                <GatsbyImage alt="" image={item.asset.gatsbyImageData} />
              )}

              {item._type === 'videoFile' && (
                <video
                  className={styles.video}
                  src={item.file.asset.url}
                  muted
                  loop
                  autoPlay
                  playsInline
                />
              )}

              {item._type === 'videoUrl' && (
                <video
                  src={item.url}
                  className={styles.video}
                  muted
                  loop
                  autoPlay
                  playsInline
                />
              )}
            </IntersectionAnimation>
          ))}
        </div>
      </IntersectionAnimation>
    </SliceConfig>
  );
};

export default Media;

import React, { useEffect, useState } from 'react';
import * as styles from './styles.module.scss';
import PlaygroundSection from '../PlaygroundSection';
import { useApp } from '@cmc/opto-toolkit/hooks';
import type { TInspectorDirectory } from '@cmc/opto-toolkit/playground';

interface IProps {
  componentName: string;
  directory: TInspectorDirectory;
}

const TSXInspector = ({ componentName, directory }: IProps) => {
  const [textContent, setTextContent] = useState(``);

  useEffect(() => {
    const fetchScssTextContent = async () => {
      try {
        const response = await fetch(`/${directory}/${componentName}.tsx`);

        if (response.status === 404) {
          throw new Error(`File not found`);
        }

        const text = await response.text();
        setTextContent(text);
      } catch (error: unknown) {
        if (error instanceof Error) {
          setTextContent(error.message);
        } else {
          console.error(error);
          setTextContent(`An unknown error occured`);
        }
      }
    };

    setTextContent(``);
    fetchScssTextContent();
  }, [componentName]);

  const { updateNotification } = useApp();

  const copyTSXToClipboard = () => {
    navigator.clipboard.writeText(textContent);
    updateNotification(`Copied to clipboard!`);
  };

  return (
    <PlaygroundSection
      title="TSX"
      button={{
        icon: 'copy',
        labelText: `Copy TSX to clipboard`,
        onClick: copyTSXToClipboard
      }}
    >
      <pre className={styles.code}>{textContent}</pre>
    </PlaygroundSection>
  );
};

export default TSXInspector;

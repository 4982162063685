import React from 'react';
import { IntersectionAnimation } from '@cmc/opto-common/components';
import { ISliceConfig } from '@cmc/opto-sanity';
import { SliceConfig } from '@cmc/opto-toolkit/components';
import * as styles from './styles.module.scss';

interface IProps {
  data: {
    title: string;
    sliceConfig: ISliceConfig;
  };
}

const Hero = ({ data: { title, sliceConfig } }: IProps) => {
  return (
    <SliceConfig config={sliceConfig}>
      <div className={styles.textContainer}>
        <IntersectionAnimation>
          <h1 className="t-d1">{title}</h1>
        </IntersectionAnimation>
      </div>
    </SliceConfig>
  );
};

export default Hero;

import React, { CSSProperties } from 'react';
import { PortableText } from '@portabletext/react';
import cn from 'classnames';
import * as styles from './styles.module.scss';

interface IProps {
  rawText: any;
  style?: CSSProperties;
  className?: string;
}

const customComponents = (style?: CSSProperties) => ({
  listItem: {
    number: ({ children }: any) => {
      const nodeStyle = children.props.node.style;
      return (
        <li className={cn(styles.orderedListItem, nodeStyle)}>{children}</li>
      );
    },
    bullet: ({ children }: any) => {
      const nodeStyle = children.props?.node.style || 't-b2';
      return (
        <li className={cn(styles.unorderedListItem, nodeStyle)}>{children}</li>
      );
    }
  },
  block: {
    normal: ({ children }: any) => (
      <p className="t-b1" style={style}>
        {children}
      </p>
    ),
    d1: ({ children }: any) => (
      <h2 style={style} className="t-d1">
        {children}
      </h2>
    ),
    d2: ({ children }: any) => (
      <h2 style={style} className="t-d2">
        {children}
      </h2>
    ),
    h1: ({ children }: any) => (
      <h2 style={style} className="t-h1">
        {children}
      </h2>
    ),
    h2: ({ children }: any) => (
      <h3 style={style} className="t-h2">
        {children}
      </h3>
    ),
    b1: ({ children }: any) => (
      <p style={style} className="t-b1">
        {children}
      </p>
    ),
    b2: ({ children }: any) => (
      <p style={style} className="t-b2">
        {children}
      </p>
    ),
    caption: ({ children }: any) => (
      <p style={style} className="t-caption">
        {children}
      </p>
    ),
    button: ({ children }: any) => (
      <p style={style} className="t-button">
        {children}
      </p>
    )
  },
  marks: {
    paragraphSection: ({ children }: any) => {
      return (
        <span style={{ display: `inline-block`, marginBottom: `0.5rem` }}>
          {children}
        </span>
      );
    },
    link: ({ children, value }: any) => {
      const rel = value.href.startsWith('/')
        ? undefined
        : 'noreferrer noopener';
      return (
        <a style={{ textDecoration: `underline` }} href={value.href} rel={rel}>
          {children}
        </a>
      );
    }
  }
});

const PortableTextRenderer = ({ rawText, className, style }: IProps) => (
  <div className={cn(styles.container, className)}>
    <PortableText value={rawText} components={customComponents(style)} />
  </div>
);

export default PortableTextRenderer;

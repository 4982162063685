// extracted by mini-css-extract-plugin
export var content = "styles-module--content--a6b79";
export var contentContainer = "styles-module--contentContainer--188b1";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--47657";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var overflowContainer = "styles-module--overflowContainer--d7236";
export var properties = "styles-module--properties--5b610";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var titleContainer = "styles-module--titleContainer--72feb";
import React, { CSSProperties } from 'react';
import cn from 'classnames';
import { ITypestyle } from '@cmc/opto-sanity';
import { useASG } from '@cmc/opto-toolkit/hooks';
import { TActiveBreakpoint } from '../..';
import * as styles from './styles.module.scss';

interface IProps {
  className?: string;
  typestyle: ITypestyle;
  activeBreakpoint: TActiveBreakpoint;
}

const TypestyleRow = ({ className, typestyle, activeBreakpoint }: IProps) => {
  const { getFontById } = useASG();

  const { fontSizes, uppercase } = typestyle;

  const fontSize = fontSizes[`${activeBreakpoint}`];
  const lineHeight = fontSizes[`${activeBreakpoint}LineHeight`];
  const letterSpacing = fontSizes[`${activeBreakpoint}LetterSpacing`];

  const typestyleStyles: () => CSSProperties = () => {
    const font = getFontById(typestyle.font._id);
    if (!font) return {};

    return {
      fontSize,
      lineHeight,
      letterSpacing,
      textTransform: uppercase ? `uppercase` : `none`,
      fontFamily: font.title
    };
  };

  return (
    <div className={cn('t-b1', styles.container, className)}>
      <div style={typestyleStyles()} className={styles.title}>
        {typestyle.title}
      </div>

      <div className={styles.fontProperties}>
        <div className={styles.propertyField}>{fontSize}</div>
        <div className={styles.propertyField}>{lineHeight}</div>
        <div className={styles.propertyField}>{letterSpacing}</div>
      </div>
    </div>
  );
};

export default TypestyleRow;

import React from 'react';
import { SliceConfig } from '@cmc/opto-toolkit/components';
import {
  IntersectionAnimation,
  PortableTextRenderer,
  UniversalLink
} from '@cmc/opto-common/components';
import { IFeaturedText } from '@cmc/opto-sanity';
import * as styles from './styles.module.scss';

interface IProps {
  data: IFeaturedText;
}

const FeaturedText = ({ data: { links, _rawText, sliceConfig } }: IProps) => (
  <SliceConfig config={sliceConfig}>
    <div className={styles.content}>
      <IntersectionAnimation>
        <PortableTextRenderer rawText={_rawText} />
      </IntersectionAnimation>

      {links?.[0] && (
        <IntersectionAnimation delay={150}>
          <div className={styles.links}>
            {links?.map((link, i) => (
              <UniversalLink link={link} key={i} variant="primary" />
            ))}
          </div>
        </IntersectionAnimation>
      )}
    </div>
  </SliceConfig>
);

export default FeaturedText;

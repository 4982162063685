// extracted by mini-css-extract-plugin
export var content = "styles-module--content--d923b";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--9e9c8";
export var gridItem = "styles-module--gridItem--33ed8";
export var gridItemContent = "styles-module--gridItemContent--659f2";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var threeColumn = "styles-module--threeColumn--ef87a";
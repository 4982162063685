// extracted by mini-css-extract-plugin
export var appear = "styles-module--appear--da9b8";
export var appearDown = "styles-module--appear-down--d4754";
export var appearLeft = "styles-module--appear-left--85b20";
export var appearRight = "styles-module--appear-right--e5ef1";
export var appearUp = "styles-module--appear-up--ac393";
export var container = "styles-module--container--5fce8";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--4a63c";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var nextButton = "styles-module--nextButton--20703";
export var noPrevButton = "styles-module--noPrevButton--369fa";
export var prevButton = "styles-module--prevButton--1d04b";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
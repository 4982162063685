// extracted by mini-css-extract-plugin
export var border = "styles-module--border--d6863";
export var bottomLeftEdge = "styles-module--bottomLeftEdge--a6d2b";
export var bottomRightEdge = "styles-module--bottomRightEdge--d4a32";
export var bottomText = "styles-module--bottomText--5dc88";
export var container = "styles-module--container--0d361";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var rgb = "styles-module--rgb--9a792";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
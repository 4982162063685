// extracted by mini-css-extract-plugin
export var desktop = "1340px";
export var dragLabel = "styles-module--dragLabel--5359d";
export var dragLabelContainer = "styles-module--dragLabelContainer--885a3";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var rightContent = "styles-module--rightContent--d141d";
export var slider = "styles-module--slider--fd5e0";
export var sliderContainer = "styles-module--sliderContainer--41c12";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var tag = "styles-module--tag--1d2cf";
export var tags = "styles-module--tags--6d662";
export var topContent = "styles-module--topContent--ecff2";
export var video = "styles-module--video--9b7f1";
import React from 'react';
import cn from 'classnames';
import { ISliceConfig } from '@cmc/opto-sanity';
import { LayoutProvider } from '@cmc/opto-common/components';
import * as styles from './styles.module.scss';

interface IProps {
  children: React.ReactNode;
  config?: ISliceConfig;
}

interface ISliceConfigColors {
  color?: string;
  backgroundColor?: string;
}

const SliceConfig = ({ children, config }: IProps) => {
  const { backgroundColor, textColor, slicePadding, bottomBorder } =
    config || {};
  const {
    paddingTop = `regular`,
    paddingBottom = `regular`,
    paddingX = `regular`
  } = slicePadding || {};

  const colorStyles: ISliceConfigColors = {};
  if (textColor?.hex) {
    colorStyles.color = textColor.hex;
  }
  if (backgroundColor?.hex) {
    colorStyles.backgroundColor = backgroundColor.hex;
  }

  return (
    <div
      style={colorStyles}
      className={cn(styles.container, {
        [styles.paddingSidesRegular]: paddingX === `regular`,
        [styles.paddingSidesSmall]: paddingX === `small`,
        [styles.paddingSidesTiny]: paddingX === `tiny`
      })}
    >
      <LayoutProvider maxWidth>
        <div
          className={cn(styles.content, {
            [styles.paddingTopRegular]: paddingTop === `regular`,
            [styles.paddingTopSmall]: paddingTop === `small`,
            [styles.paddingTopTiny]: paddingTop === `tiny`,
            [styles.paddingBottomRegular]: paddingBottom === `regular`,
            [styles.paddingBottomSmall]: paddingBottom === `small`,
            [styles.paddingBottomTiny]: paddingBottom === `tiny`,
            [styles.bottomBorder]: bottomBorder
          })}
        >
          {children}
        </div>
      </LayoutProvider>
    </div>
  );
};

export default SliceConfig;

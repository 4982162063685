import React from 'react';
import { useApp } from '@cmc/opto-toolkit/hooks';
import { SVG } from '@cmc/opto-common/components';
import * as styles from './styles.module.scss';

interface IProps {
  text: string;
  className?: string;
}

const CopyText = ({ text, className }: IProps) => {
  const { updateNotification } = useApp();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(text);
    updateNotification(`Copied to clipboard!`);
  };

  return (
    <div className={styles.container}>
      <button className={styles.button} type="button" onClick={copyToClipboard}>
        <SVG svg="copy" className={styles.icon} />
      </button>

      <div className={className}>{text}</div>
    </div>
  );
};

export default CopyText;
